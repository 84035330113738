import _taggedTemplateLiteral from "/runner/_work/r-client/r-client/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6;

/*
 * CODE GENERATED AUTOMATICALLY WITH GraphQL Codegen
 * THIS FILE SHOULD NOT BE EDITED MANUALLY.
 */
import { gql } from '@apollo/client';
export var OfferingCardFragmentDoc = gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  fragment OfferingCard on Offering {\n    id\n    slug\n    airdrop\n    companyName\n    following\n    description\n    amountRaised\n    amountRaisedWithExternalCents\n    externalInvestmentsAmountTooltip\n    externalInvestorsQuantity\n    externalInvestorsDescription\n    cardTooltipText\n    cardImageUrl\n    amountRaised\n    badges\n    spotlight\n    minInvestmentAmountCents\n    regulationForDisplay\n    cardComplianceText\n    hostingEntityForDisplay\n    calculatedMinInvestmentAmount\n    logoUrl(resize: { width: 60, height: 60, type: fit })\n    logoUrl2x: logoUrl(resize: { width: 120, height: 120, type: fit })\n    tags {\n      id\n      colorHex\n      displayName\n      slug\n      topLevelSectorTag {\n        colorHex\n        displayName\n        description\n        vertical\n        group\n      }\n      impact\n      vertical\n      group\n    }\n    address {\n      id\n      state\n      city\n    }\n    state\n    flexibleDealTerms {\n      title\n      value\n      nextTierValue\n      showOnTombstone\n      type\n      xValueForDealCard\n      id\n    }\n    xSecurity {\n      xValuationCapCents\n      xValuationCents\n    }\n    timeRemainingValueForDisplay\n    timeRemainingUnitForDisplay\n    timeToInvestBadgeParams {\n      value\n      unit\n    }\n    hasClosed\n    reviewsCount\n    testingTheWaters\n    testingTheWatersFirstDaysOff\n    amountRaisedCents\n    maxGoalReached\n    investorsCount\n    crypto\n    verticalTag\n    trending\n    investmentBadge {\n      id\n      description\n      state\n      amountCents\n    }\n    hostingEntity\n  }\n"])));
export var FollowOfferingDocument = gql(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  mutation FollowOffering($input: FollowCreateInput!) {\n    follow(input: $input) {\n      target {\n        ... on Offering {\n          following\n        }\n      }\n      errors {\n        message\n      }\n    }\n  }\n"])));
export var OfferingsSearchDocument = gql(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  query OfferingsSearch {\n    xOfferingsSearch(searchParams: {}, first: 9) {\n      nodes {\n        id\n        address {\n          state\n          city\n        }\n        badges\n        cardImageUrl\n        cardTooltipText\n        companyName\n        description\n        following\n        indexInAllOfferings\n        logoUrl\n        numOfAllOfferings\n        slug\n        state\n        tags {\n          id\n          colorHex\n          displayName\n          slug\n        }\n        terms\n      }\n    }\n  }\n"])));
export var OfferingDocument = gql(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  query Offering($slug: String!) {\n    offering(slug: $slug) {\n      id\n      companyName\n    }\n  }\n"])));
export var SavedOfferingsDocument = gql(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  query SavedOfferings($userSlug: String!) {\n    savedOfferingsForUser(userSlug: $userSlug, first: 12) {\n      nodes {\n        id\n        companyName\n      }\n    }\n  }\n"])));
export var UnfollowOfferingDocument = gql(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  mutation UnfollowOffering($input: FollowDeleteInput!) {\n    unfollow(input: $input) {\n      target {\n        ... on Offering {\n          following\n        }\n      }\n      errors {\n        message\n      }\n    }\n  }\n"])));