export var E_OFFERING_INVESTMENT_BADGE_NAME;

(function (E_OFFERING_INVESTMENT_BADGE_NAME) {
  E_OFFERING_INVESTMENT_BADGE_NAME["COMMITTED"] = "Committed";
  E_OFFERING_INVESTMENT_BADGE_NAME["WAITLISTED"] = "Waitlisted";
  E_OFFERING_INVESTMENT_BADGE_NAME["CANCELLED"] = "Cancelled";
})(E_OFFERING_INVESTMENT_BADGE_NAME || (E_OFFERING_INVESTMENT_BADGE_NAME = {}));

export var E_MARKETING_BADGES;

(function (E_MARKETING_BADGES) {
  E_MARKETING_BADGES["SPOTLIGHT"] = "Spotlight";
})(E_MARKETING_BADGES || (E_MARKETING_BADGES = {}));